import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost

    return (
            <>
                <MetaData
                    data={data}
                    location={location}
                    type="article"
                />
                <Helmet>
                    <style type="text/css">{`${post.codeinjection_styles}`}</style>
                </Helmet>
                <Layout>
                    <article
                    className={`post-content`}
                    >
                    <header className="post-content-header">
                        <h1 className="post-content-title">{post.title}</h1>
                    </header>


                    <div className="post-content-image">
                        <img src={ post.feature_image } alt={ post.title } className="kg-image" />
                    </div>


                    <div
                        className="post-content-body"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                    />

                    <footer className="post-content-footer">
                        {/* There are two options for how we display the byline/author-info.
                    If the post has more than one author, we load a specific template
                    from includes/byline-multiple.hbs, otherwise, we just use the
                    default byline. */}
                    </footer>
                    </article>
                </Layout>
            </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
